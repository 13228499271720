import "./App.css";
import logo from "./Cravings_logo.png";
import crave from "./crave.svg";
import twitter from "./Twitter.svg";
import facebook from "./Facebook.svg";
import instagram from "./instagramlogo.svg";
import meal from "./image 19.png";
import "./App.css";
import { Link } from "react-router-dom";

function App() {
  return (
    <div className="app-container">
      <div className="header-container">
        <div className="">
          <img src={logo} style={{ width: "60px" }} className="" />
        </div>

        <div
          style={{ paddingTop: "20px", marginRight: "-30px" }}
          className="crave-image"
        >
          <img src={crave} style={{ width: "34vw" }} className="" />
        </div>
        <div className="logo-container">
          <Link to="https://instagram.com/trycravings?igshid=YTQwZjQ0NmI0OA==">
            <div
              style={{
                width: "3vw",
                height: "3vw",
                border: "1px solid white",
                display: "flex",
                justifyContent: "center",
                borderRadius: "50%",
              }}
            >
              <img src={instagram} style={{ width: "2vw" }} />
            </div>
          </Link>
          <Link to="https://twitter.com/trycravings">
            <div
              style={{
                width: "3vw",
                height: "3vw",
                border: "1px solid white",
                display: "flex",
                justifyContent: "center",
                borderRadius: "50%",
              }}
            >
              <img src={twitter} style={{ width: "2vw" }} />
            </div>
          </Link>
          <Link to="/">
            <div
              style={{
                width: "3vw",
                height: "3vw",
                border: "1px solid white",
                display: "flex",
                justifyContent: "center",
                borderRadius: "50%",
              }}
            >
              <img src={facebook} style={{ width: "2vw" }} />
            </div>
          </Link>
        </div>
      </div>
      <div
        style={{
          color: "white",
          display: "flex",
          justifyContent: "center",
          fontFamily: "Hello mixed",
          fontWeight: "bold",
          fontSize: "7vw",
          paddingTop: "20px",
        }}
        className="coming-soon"
      >
        COMING SOON!
      </div>

      <div
        className="working"
        style={{
          color: "white",
          paddingTop: "5vh",
          display: "flex",
          justifyContent: "center",
          // fontSize: "2em",
          fontStyle: "italic",
          textAlign: "center",
        }}
      >
        We are working to satisty your cravings
      </div>
      <div>
        <img
          src={meal}
          className="meal"
          style={{ width: "100%", objectFit: "fill" }}
        />
      </div>
    </div>
  );
}

export default App;
